<template>
  <v-dialog v-model="dialog" :width="'350'">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="icon"
        style="margin-left: -7px"
        icon
        @click="open"
        :disabled="disabled_prop"
        ><v-icon small>mdi-plus-circle</v-icon></v-btn
      >
      <v-btn v-else-if="viewDate" text @click="open" :disabled="disabled_prop"
        ><v-icon class="mr-2" small>$clock</v-icon>
        {{ $t("consent-form.assign_day") }}</v-btn
      >
      <v-list-item
        v-else
        class="apListItem"
        @click="open"
        :disabled="disabled_prop"
      >
        <v-icon class="mr-2" small>$clock</v-icon
        >{{ $t("consent-form.assign_day") }}</v-list-item
      >
    </template>
    <v-card>
      <form
        action
        data-vv-scope="date-time-form"
        @submit.prevent="validateForm('date-time-form')"
      >
        <v-card-title>
          <div style="text-transform: uppercase; text-decoration: underline">
            {{ $t("consent-form.assign_day_hour") }}
          </div>
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12"
              >{{ $t("appointments.availability_client") }}
              {{ appointment.availability }}
            </v-col>
            <v-col class="text-center" cols="12" md="12">
              <v-date-picker
                first-day-of-week="1"
                :allowed-dates="allowedDates"
                color="primary"
                no-title
                v-model="form.date"
                :min="min"
                :max="max"
                @change="fetchAppointentsByStudioTattoerDate"
                ref="picker"
              >
              </v-date-picker>
            </v-col>
            <v-col cols="6">
              <label style="line-height: 1">Horas</label>
              <v-autocomplete
                :items="hoursDate(form.date)"
                outlined
                dense
                v-model="form.time"
                :item-disabled="disabled"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="7" v-if="false">
              <template v-for="(h, i) in horario(form.date)">
                <v-btn
                  v-for="(b, j) in hours(h[0], h[1])"
                  :key="'h- ' + i + '-' + j"
                  :value="b"
                  :outlined="form.time != b"
                  class="mr-3 mb-4"
                  large
                  elevation="0"
                  dense
                  @click="
                    $set(form, 'time', b);
                    time_error = false;
                  "
                  :disabled="disabled(b)"
                  style="border-radius: 10px; height: 35px"
                  :color="time_error ? 'red' : 'primary'"
                >
                  {{ b }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
            style="height: 25px; width: 100px"
            elevation="0"
            outlined
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            type="submit"
            elevation="0"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
          >
            <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import * as moment from "moment";
export default {
  name: "LaborExangeDayAssaing",
  data() {
    return {
      dialog: false,
      min: null,
      max: null,
      form: {},
      appointments: [],
      state: false,
      dates: [],
      datesTattooer: [],
      today: null,
      time_error: false,
    };
  },
  props: ["appointment", "icon", "viewDate", "disabled_prop"],
  mounted() {
    this.today = moment().format("YYYY-MM-DD");

    this.fetchAll();
    this.fetchAllVacationsTattooer(
      this.appointment.tattooer.id,
      this.appointment.studio.id
    );
  },
  methods: {
    hoursDate(date) {
      let h = [];
      this.horario(date).forEach((x) => {
        this.hours(x[0], x[1]).forEach((hs) => {
          if (!this.disabled(hs)) h.push(hs);
        });
      });
      return h;
    },
    ...mapActions("appointments", [
      "getMinMaxDates",
      "getAppointentsByStudioTattoerDate",
      "addAppointmensDate",
    ]),
    open() {
      this.getMinMaxDates(this.appointment.id).then((data) => {
        //console.log(data);
        this.min = data.find((x) => x.type === "labor_exange_start");
        this.min = this.min ? this.min.date : this.today;
        this.max = data.find((x) => x.type === "labor_exange_end");
        this.max = this.max ? this.max.date : null;
        this.time_error = false;
        this.state = false;
        if (this.max) {
          let today = new Date();
          let maxDate = new Date(this.max.substring(0, 10));
          console.log(today, maxDate);
          if (today.getTime() > maxDate.getTime()) {
            this.$confirm(this.$t("appointments.date_passed")).then(() => {
              this.$router.push({
                name: "appointmentView",
                params: { id: this.appointment.id },
              });
            });
            return;
          }
        }
        this.dialog = true;
        this.form.date = this.min;
        this.$nextTick(() => {
          console.log(this.$refs.picker);
          delete this.form.date;
        });
      });
    },
    fetchAppointentsByStudioTattoerDate() {
      this.state = true;
      this.getAppointentsByStudioTattoerDate({
        studio_id: this.appointment.studio.id,
        tattooer_id: this.appointment.tattooer.id,
        date: this.form.date,
      }).then((data) => {
        this.appointments = data;
      });
    },

    hours(min, max) {
      let mins = [":00", ":15", ":30", ":45"];
      let hs = [];
      let mn = +min.split(":")[0];
      let mx = +max.split(":")[0];

      for (let i = mn; i < mx; i++) {
        mins.forEach((m) => {
          if (i < 10) hs.push("0" + i + m);
          else hs.push(i + m);
        });
      }
      return hs;
    },

    disabled(h) {
      //desactivar las horas ocupadas!!!
      h = this.form.date + " " + h;
      h = new Date(h);
      let app = this.appointments.find((x) => {
        let time = x.available_dates.find((y) => {
          if (
            new Date(y.date).getTime() <= h.getTime() &&
            new Date(y.endDate).getTime() > h.getTime()
          )
            return true;
        });
        if (time) return true;
        return false;
      });

      if (app) return true;
      return false;
    },
    horario(date) {
      if (date) {
        return this.timeTable(date)[this.dayOfWeek(date)];
      }
      return [];
    },

    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },

    filter(date) {
      if (this.appointment.tattooer && this.appointment.tattooer.id !== -1)
        return this.appointment.tattooer.tattooer_studios.find((element) => {
          if (element.studio_id !== this.appointment.studio.id) {
            return false;
          }
          if (element.to) {
            // comprovar que estigui al interval
            return element.from <= date && date <= element.to;
          } else {
            //comprovar que sigui mes gran que from
            return element.from <= date;
          }
        });
      return true;
    },
    timeTable(date) {
      if (
        this.filter(date) !== true &&
        this.filter(date) !== undefined &&
        this.filter(date).tattooer_timetable
      )
        return this.filter(date).tattooer_timetable;
      return this.appointment.studio.timetable;
    },

    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) return false;
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //console.log(t);
        return false;
      }
      return this.horario(date).length;
      return false;
    },
    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      console.log(tattooer);
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    async validateForm(scope) {
      this.errors.clear();
      //console.log("validando");

      let val_time = true;
      if (!this.form.time) {
        val_time = false;
        this.time_error = true;
      }

      this.$validator.validateAll(scope).then((result) => {
        if (result && val_time) {
          this.addAppointmensDate({
            id: this.appointment.id,
            date: this.form.date,
            time: this.form.time,
            timezone: -new Date().getTimezoneOffset(),
          }).then((data) => {
            this.dialog = false;
            this.$emit("update");
          });
        }
      });
    },
  },
};
</script>

<style lang="sass">
.selected
  color: var(--v-primary-base) !important
</style>
